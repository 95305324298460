'use strict';

var formValidation = require('base/components/formValidation');

module.exports = {

    sendReactivation: function () {
        $('.user-reactivation-form').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('.user-reactivation-form').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    form.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        $('.user-reactivation-title').text(data.receivedMsgHeading);
                        $('.user-reactivation-body').empty().append('<p>' + data.receivedMsgBody + '</p>');
                        if (!data.mobile) {
                            $('#reactivationSubmitEmailButton').text(data.buttonText).attr('data-dismiss', 'modal');
                        } else {
                            $('.send-email-btn').empty()
                                .html('<a href="' + data.returnUrl + '" class="btn btn-primary btn-block">' + data.buttonText + '</a>');
                        }
                    }
                },
                error: function () {
                    form.spinner().stop();
                }
            });
            return false;
        });
    },

    clearReactivationForm: function () {
        $('#login .modal').on('hidden.bs.modal', function () {
            $('#user-reactivation-email').val('');
            $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
        });
    }

};
