'use strict';

var loginBase = require('base/login/login');
var formValidation = require('base/components/formValidation');
var createErrorNotification = require('base/components/errorNotification');

$('#login .modal').on('hidden.bs.modal', function () {
    var registerAlert = $('.register-alert');
    registerAlert.remove();
});

function emailRegisterValidation(form, data) {
    if (data && data.error) {
        var p = $('.forgotpassword');
        p.append('<div class="alert alert-danger register-alert" role="alert">'
            + data.error.join('<br/>') + '</div>');
    }
}

function login() {
    $('form.login').submit(function (e) {
        var form = $(this);
        e.preventDefault();
        var url = form.attr('action');
        form.spinner().start();
        $('form.login').trigger('login:submit', e);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                form.spinner().stop();
                if (!data.success) {
                    if (data.isMigrated) {
                        // Trigger modal
                        $('#requestMigratedPasswordResetModal').modal('toggle');
                    } else {
                        formValidation(form, data);
                        $('form.login').trigger('login:error', data);
                    }
                    // dataLayer Failed Login
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push(data.failLoginGtmEvent);
                } else {
                    // Feature DAB-2819:  Create email in sessionStorage //
                    var emailEncode = window.btoa($('#login-form-email').val());
                    localStorage.setItem('email', emailEncode);
                    $('form.login').trigger('login:success', data);
                    location.href = data.redirectUrl;
                    // dataLayer success Login
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push(data.successLoginGtmEvent);
                }
            },
            error: function (data) {
                if (data.responseJSON.redirectUrl) {
                    window.location.href = data.responseJSON.redirectUrl;
                } else {
                    $('form.login').trigger('login:error', data);
                    form.spinner().stop();
                }
            }
        });
        return false;
    });
}


function resetPassword() {
    $('.reset-password-form').submit(function (e) {
        var registerAlert = $('.register-alert');
        registerAlert.remove();
        var form = $(this);
        e.preventDefault();
        var url = form.attr('action');
        form.spinner().start();
        $('.reset-password-form').trigger('login:register', e);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                form.spinner().stop();
                if (!data.success) {
                    if (data.userError) {
                        emailRegisterValidation(form, data);
                    } else {
                        formValidation(form, data);
                    }
                } else {
                    $('.request-password-title').text(data.receivedMsgHeading);
                    $('.request-password-body').empty()
                        .append('<p>' + data.receivedMsgBody + '</p>');
                    if (!data.mobile) {
                        $('.send-email-btn .submit-email-button').text(data.buttonText)
                            .attr('data-dismiss', 'modal');

                        $('#submitEmailButton').text(data.buttonText)
                            .attr('data-dismiss', 'modal');
                    } else {
                        $('.send-email-btn').empty()
                            .html('<a href="'
                                + data.returnUrl
                                + '" class="btn btn-primary btn-block">'
                                + data.buttonText + '</a>'
                            );
                    }
                    /**
                    * DAB-4756 BEGIN - push datalayer reset password event Google Tag manager
                    */
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push(data.resetPasswordGtmEvent);
                    /**
                    * DAB-4756 END
                    */
                }
            },
            error: function () {
                form.spinner().stop();
            }
        });
        return false;
    });
}

function register() {
    $('form.registration').submit(function (e) {
        var form = $(this);
        e.preventDefault();
        var url = form.attr('action');
        form.spinner().start();
        $('form.registration').trigger('login:register', e);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                form.spinner().stop();
                if (!data.success) {
                    /**
                    * DAB-4756 BEGIN - push datalayer Register Fail event Google Tag manager
                    */
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push(data.failRegisterGtmEvent);
                    /**
                    * DAB-4756 END
                    */
                    formValidation(form, data);
                    if (data.externallyAuthenticated) {
                        createErrorNotification($('.error-messaging'), data.errorMessage);
                    }
                } else {
                    /**
                    * DAB-4756 BEGIN - push datalayer Register Success event Google Tag manager
                    */
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push(data.successRegisterGtmEvent);
                    /**
                    * DAB-4756 END
                    */
                    location.href = data.redirectUrl;
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                }

                form.spinner().stop();
            }
        });
        return false;
    });
}

$('#account-register').on('click', function () {
    var registerTab = $('#register-tab');
    var loginTab = $('#login-tab');
    var registerTitle = $('.register-title');
    var loginTitle = $('.login-title');
    var registerDiv = $('#register');
    var loginDiv = $('#login');
    registerTab[0].classList.add('active');
    loginTab[0].classList.remove('active');
    registerTitle[0].classList.remove('hide');
    loginTitle[0].classList.add('hide');
    registerDiv[0].classList.add('active');
    loginDiv[0].classList.remove('active');
});

$('#account-register-checkout').on('click', function () {
    var registerTab = $('#register-tab');
    var loginTab = $('#login-tab');
    var registerDiv = $('#register');
    var loginDiv = $('#login');
    registerTab[0].classList.add('active');
    loginTab[0].classList.remove('active');
    registerDiv[0].classList.add('active');
    loginDiv[0].classList.remove('active');
});

$(document).ready(function () {
    $('.form-group-password a').on('click', function (event) {
        event.preventDefault();
        if ($('.form-group-password input').attr('type') == 'text') {
            $('.form-group-password input').attr('type', 'password');
            $('.form-group-password i').addClass('fa-eye-slash');
            $('.form-group-password i').removeClass('fa-eye');
        } else if ($('.form-group-password input').attr('type') == 'password') {
            $('.form-group-password input').attr('type', 'text');
            $('.form-group-password i').removeClass('fa-eye-slash');
            $('.form-group-password i').addClass('fa-eye');
        }
    });
});

$(document).ready(function(){
    function updateLabel() {
        var $input = $('#reset-password-email');
        var $label = $('#reset-pass-label');
        if ($input.is(':focus') || $input.val() !== '') {
            $label.addClass('focused');
        } else {
            $label.removeClass('focused');
        }
    }
    $('#reset-password-email').on('focus blur input', function(){
        updateLabel();
    });

    updateLabel();
});

$(document).ready(function () {
    $('.form-group-confirmpassword a').on('click', function (event) {
        event.preventDefault();
        if ($('.form-group-confirmpassword input').attr('type') == 'text') {
            $('.form-group-confirmpassword input').attr('type', 'password');
            $('.form-group-confirmpassword i').addClass('fa-eye-slash');
            $('.form-group-confirmpassword i').removeClass('fa-eye');
        } else if ($('.form-group-confirmpassword input').attr('type') == 'password') {
            $('.form-group-confirmpassword input').attr('type', 'text');
            $('.form-group-confirmpassword i').removeClass('fa-eye-slash');
            $('.form-group-confirmpassword i').addClass('fa-eye');
        }
    });
});


loginBase.login = login;
loginBase.resetPassword = resetPassword;
loginBase.register = register;
module.exports = loginBase;
